export default [
  {
    title: 'Курсы',
    route: 'student-courses',
    icon: 'BriefcaseIcon',
    action: '*',
    resource: '*',
  },
  {
    title: 'Вебинары',
    route: 'student-webinars',
    icon: 'VideoIcon',
    action: '*',
    resource: '*',
  },
  {
    title: 'Мои сертификаты',
    route: 'student-certificates',
    icon: 'AwardIcon',
    action: '*',
    resource: '*',
  },
  {
    title: 'Календарь',
    route: 'student-calendar',
    icon: 'CalendarIcon',
    action: '*',
    resource: '*',
  },
  {
    title: 'Библиотека',
    route: 'student-library',
    icon: 'BookIcon',
    action: '*',
    resource: '*',
  },
  {
    title: 'Форум',
    route: 'student-forum',
    icon: 'GridIcon',
    action: '*',
    resource: '*',
  },
  {
    title: 'Онлайн чат',
    route: 'student-chat',
    icon: 'MessageSquareIcon',
    action: '*',
    resource: '*',
  },
]
