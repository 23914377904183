export default [
  {
    title: 'Главная',
    route: 'home-page-dashboard',
    icon: 'HomeIcon',
    action: 'view',
    resource: 'home-dashboard',
  },
  {
    title: 'Пользователи',
    route: 'users-page-dashboard',
    icon: 'UsersIcon',
    action: 'view',
    resource: 'user',
  },
  {
    title: 'Курсы',
    route: 'courses-page-dashboard',
    icon: 'BriefcaseIcon',
    action: ['own-view'],
    resource: 'course',
  },
  {
    title: 'Вебинары',
    icon: 'VideoIcon',
    children: [
      {
        title: 'Список вебинаров',
        route: 'webinars-page-dashboard',
        action: 'view',
        resource: 'bbb-meeting',
      },
      {
        title: 'Сохраненные вебинары',
        route: 'saved-webinars-page-dashboard',
        action: 'view',
        resource: 'bbb-meeting',
      },
      // {
      //   title: 'Настройка BigBlueButton',
      //   route: 'setting-bbb-page-dashboard',
      //   action: 'view',
      //   resource: 'user',
      // },
    ],
  },
  {
    title: 'Отчет по тестирование',
    route: 'report-test-page-dashboard',
    icon: 'ClipboardIcon',
    action: 'view',
    resource: 'user',
  },
  {
    title: 'Отчет по оценка курса',
    route: 'report-feedback-page-dashboard',
    icon: 'ClipboardIcon',
    action: 'view',
    resource: 'user',
  },
  {
    title: 'Форум',
    route: 'forum-page-dashboard',
    icon: 'GridIcon',
    action: 'view',
    resource: 'chatter-discussion',
  },
  {
    title: 'Чат',
    route: 'chat-dashboard',
    icon: 'MessageSquareIcon',
    action: 'view',
    resource: 'chat',
  },
  {
    title: 'Календарь',
    route: 'calendar-page-dashboard',
    icon: 'CalendarIcon',
    action: 'view',
    resource: 'event',
  },
  {
    title: 'Библиотека',
    icon: 'BookIcon',
    children: [
      {
        title: 'Список файлов',
        route: 'books-page-dashboard',
        action: 'view',
        resource: 'book',
      },
    ],
  },
  {
    title: 'Сертификаты',
    icon: 'AwardIcon',
    children: [
      {
        title: 'Сертификат студентов',
        route: 'student-certificates-page-dashboard',
        action: 'view',
        resource: 'certificate',
      },
      {
        title: 'Шаблон сертификатов',
        route: 'template-certificate-page-dashboard',
        action: 'view',
        resource: 'slider',
      },
    ],
  },
  {
    title: 'Управление фронтендам',
    icon: 'SettingsIcon',
    children: [
      // {
      //   title: 'Меню',
      //   route: 'menu-landing-page-dashboard',
      //   action: 'view',
      //   resource: 'menu',
      // },
      {
        title: 'Слайдеры',
        route: 'slider-landing-page-dashboard',
        action: 'view',
        resource: 'slider',
      },
      {
        title: 'О проекте',
        route: 'about-project-landing-page-dashboard',
        action: 'create-or-update',
        resource: 'about',
      },
      {
        title: 'Для кого создан проект',
        route: 'created-for-landing-page-dashboard',
        action: 'view',
        resource: 'created-project',
      },
      {
        title: 'Наши менторы',
        route: 'our-mentors-landing-page-dashboard',
        action: 'view',
        resource: 'slider',
      },
      {
        title: 'Услуги',
        route: 'service-landing-page-dashboard',
        action: 'view',
        resource: 'service',
      },
      {
        title: 'Отзывы',
        route: 'testimonials-landing-page-dashboard',
        action: 'view',
        resource: 'testimonial',
      },
      {
        title: 'Новости',
        route: 'blogs-landing-page-dashboard',
        action: 'view',
        resource: 'blog',
      },
      {
        title: 'Футер',
        route: 'footer-landing-page-dashboard',
        action: 'view',
        resource: 'footer-menu',
      },
    ],
  },
  {
    title: 'Справочники',
    icon: 'BookOpenIcon',
    children: [
      {
        title: 'Справочник категорий',
        route: 'category-book-page-dashboard',
        action: 'view',
        resource: 'category',
      },
      {
        title: 'Сферы деятельности',
        route: 'manual-type-work-page-dashboard',
        action: 'view',
        resource: 'category',
      },
    ],
  },
  {
    title: 'Настройка',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Роли и привилегии',
        route: 'setting-roles-permission',
        action: 'view',
        resource: 'role',
      },
    ],
  },
]
