<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
        >
          <feather-icon
              icon="MenuIcon"
              size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block"/>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
          class="dropdown-user"
          v-if="userData"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userData.name || userData.email }}
            </p>
            <span class="user-status">{{ roleTranslate[userData.roles[0]] }}</span>
          </div>
          <b-avatar
              size="40"
              :src="userData.photo ? 'https://edu.startupchoikhona.tj/backend/'+userData.photo : ''"
              variant="light-primary"
              badge
              class="badge-minimal"
              badge-variant="success"
          >
            <feather-icon
                v-if="!userData.name"
                icon="UserIcon"
                size="22"
            />
          </b-avatar>
        </template>
        <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="redirectToMain"
        >
          <div>
            <feather-icon
                size="16"
                icon="ServerIcon"
                class="mr-50"
            />
            <span>Главная страница</span>
          </div>

        </b-dropdown-item>
        <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="redirectToProfile"
        >
          <feather-icon
              size="16"
              icon="UserIcon"
              class="mr-50"
          />
          <span>Профиль</span>
        </b-dropdown-item>

        <b-dropdown-divider v-show="false"/>

        <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="logout"
        >
          <feather-icon
              size="16"
              icon="LogOutIcon"
              class="mr-50"
          />
          <span>Выйти</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
      roleTranslate: {
        admin: 'Администратор',
        student: 'Студент',
        teacher: 'Ментор',
      },
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  methods: {
    logout() {
      this.$http.post('https://edu.startupchoikhona.tj/backend/api/logout', {})
          .then(() => {
            // Remove userData from localStorage
            // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

            // Remove userData from localStorage
            localStorage.removeItem('userData')

            // Reset ability
            this.$ability.update(initialAbility)

            // Redirect to login page
            this.$router.push({ name: 'login' })
          })
    },
    redirectToMain() {
      this.$router.push({ name: 'home-landing' })
    },
    redirectToProfile() {
      this.$router.push({ name: 'profile-page-dashboard' })
    },
  },
}
</script>

<style>
.dropdown-menu.dropdown-menu-right.show {
  width: 100% !important;
}
</style>
